
import { Component, Vue, Watch } from 'vue-property-decorator';

import ItemInfo from '@/components/Item/Item.vue';
import TopPlace from '@/components/Event/TopPlace.vue';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Switcher from '@/components/Switcher.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import DateChanger from '@/components/DateChanger.vue';
import Countdown from '@/components/Event/Countdown.vue';

import {
  EventTopFilter,
  GetEventTopResponse
} from '@/requests/Event/getTop/getEventTop';

import { endOfDay, endOfToday, getDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Item } from '@/types/Item';

export const EVENT_MONTH_END_TIME = new Date('2024-11-01T00:00:00');

@Component({
  components: {
    TextHeader,
    ItemInfo,
    Switcher,
    DateChanger,
    Countdown,
    TopPlace,
    LoginGeneralButton
  }
})
export default class EventTop extends Vue {
  filter: EventTopFilter = 'day';
  selectedDay: string = '';

  async mounted() {
    await this.$store.dispatch('event/fetchTop', {filter: 'month'});
    await this.$store.dispatch('event/fetchTop', {filter: 'day', refetch: true});
  }

  get items(): Item[] {
    return [
      {
        id: 1,
        name: 'Каждые три часа',
        image: require('@/assets/img/event/banner-item-1.png'),
        type: 'money',
        color: 'FF8B00',
        price: 0
      },
      {
        id: 1,
        name: 'Каждый день',
        image: 'https://steamcommunity-a.akamaihd.net/economy/image/' + this.$store.state.event.top['day'].data.prizeItems[0]?.image,
        type: 'money',
        color: this.$store.state.event.top['day'].data?.prizeItems[0]?.color,
        price: 0
      },
      {
        id: 1,
        name: 'Каждый месяц',
        image: 'https://steamcommunity-a.akamaihd.net/economy/image/' + this.$store.state.event.top['month'].data.prizeItems[0]?.image,
        type: 'money',
        color: this.$store.state.event.top['month'].data?.prizeItems[0]?.color,
        price: 0
      }
    ];
  }

  get dailyEventEndTime() {
    const dayNow = getDay(new Date());
    const dayMoscow = getDay(utcToZonedTime(endOfToday(), 'Europe/Moscow'));
    return dayNow != dayMoscow
        ? endOfDay(utcToZonedTime(endOfToday(), 'Europe/Moscow'))
        : endOfToday();
  }

  get players(): GetEventTopResponse['players'] {
    return this.$store.state.event.top[this.filter].data.players;
  }

  get prizeItems(): GetEventTopResponse['prizeItems'] {
    return this.$store.state.event.top[this.filter].data.prizeItems;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get userPlace() {
    return this.$store.state.event.top[this.filter].data.userPlace;
  }

  get userWins() {
    return this.$store.state.event.top[this.filter].data.userWins;
  }

  get userScore() {
    return this.$store.state.event.top[this.filter].data.userScore;
  }

  get monthlyEventEndTime() {
    return EVENT_MONTH_END_TIME;
  }

  get userForTop() {
    if (this.user) {
      const minRank = Math.min(15, this.players.length);
      const tillLevel = this.userPlace > minRank ? minRank : this.userPlace - 1;
      const needScore =
          this.players[tillLevel - 1]?.eventScore - (this.userScore ?? 0);
      return {
        ...this.user,
        eventScore: this.userScore,
        wins: this.userWins,
        needScore,
        tillLevel
      };
    }

    return null;
  }

  @Watch('filter')
  onFilterChange() {
    this.$store.dispatch('event/fetchTop', {
      filter: this.filter,
      refetch: true
    });
  }

  @Watch('selectedDay')
  onDayChange() {
  }
}
