
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ItemInfo from '@/components/Item/Item.vue';
import { Item } from '@/types/Item';
import { GetEventTopResponse } from '@/requests/Event/getTop/getEventTop';

@Component({
  components: { ItemInfo },
})
export default class EventBanner extends Vue {
  get items(): Item[] {
    const dayPrizeItem = this.$store.state.event.top['day'].data.prizeItems[0];
    const monthPrizeItem = this.$store.state.event.top['month'].data.prizeItems[0];
    return [
      {
        id: 1,
        name: 'Каждый день',
        image: 'https://steamcommunity-a.akamaihd.net/economy/image/' + this.$store.state.event.top['day'].data.prizeItems[0]?.image ,
        type: 'money',
        color: this.$store.state.event.top['day'].data.prizeItems[0]?.color,
        price: 0,
      },
      {
        id: 1,
        name: 'Каждый месяц',
        image: 'https://steamcommunity-a.akamaihd.net/economy/image/' + this.$store.state.event.top['month'].data.prizeItems[0]?.image ,
        type: 'money',
        color: this.$store.state.event.top['month'].data.prizeItems[0]?.color,
        price: 0,
      },
    ];
  }

  get randomArcane(): Item {
    const items = [...this.rewardItems()];
    items.shift();

    const randomIdx = Math.floor(Math.random() * items.length);

    return items[randomIdx];
  }
  rewardItems(): Item[] {
    return this.$store.state.event.tasks.data.rewardItems;
  }
}
