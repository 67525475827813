
import { Component, Vue } from 'vue-property-decorator';
import Giveaway from '@/components/Giveaway/Giveaway.vue';
import { IGiveaway } from '@/types/IGiveaway';
import { Drop } from '@/types/Drop';

@Component({
  components: {
    Giveaway
  }
})
export default class Giveaways extends Vue {
  get giveawaysData(): IGiveaway[] {
    return this.$store.state.giveaways.giveaways;
  }

  mounted() {
    this.$store.dispatch('giveaways/fetchGiveaways', {refetch: true});
    this.$store.dispatch('promotions/fetch');

    this.$socket.client.on('giveaway_use', (giveawayId: number) => {
      this.$store.dispatch('giveaways/joinGiveaway', {giveawayId, otherUser: true});
    });
  }
}
